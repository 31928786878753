import React from 'react'
import styled from 'styled-components'

const Svg = styled.svg`
  display: inline-block;
  vertical-align: -0.125em;
  height: 1em;
  overflow: visible;
  font-size: inherit;
  fill: currentColor;

  &:not(:root) {
    overflow: visible;
  }
`

export function IconRewind() {
  return (
    <Svg width="20px" height="20px" color="#ffffff" viewBox="0 0 24 24">
      <title>skip-backward</title>
      <path d="M12,0.905 C12,0.721 11.903,0.587 11.71,0.505 C11.53752,0.418016836 11.3281517,0.457350203 11.199,0.601 L7.249,4.551 C7.15932638,4.63758441 7.10939056,4.757358 7.111,4.882 C7.111,5.012 7.157,5.122 7.249,5.214 L11.199,9.164 C11.346,9.311 11.517,9.343 11.71,9.26 C11.903,9.177 12,9.044 12,8.86 L12,5.82 C12.999739,5.83581518 13.9878387,6.03723291 14.914,6.414 C15.844,6.792 16.677,7.349 17.414,8.085 C18.3727138,9.03087988 19.0555128,10.2201601 19.389,11.525 C19.7295397,12.8193087 19.7295397,14.1796913 19.389,15.474 C19.0553454,16.7784817 18.3725576,17.9673969 17.414,18.913 C16.4681201,19.8717138 15.2788399,20.5545128 13.974,20.888 C12.6796913,21.2285397 11.3193087,21.2285397 10.025,20.888 C8.72051829,20.5543454 7.53160313,19.8715576 6.586,18.913 C5.91732784,18.2498447 5.37871688,17.4673664 4.998,16.606 C4.62390156,15.7650278 4.41283252,14.8606886 4.376,13.941 C4.35990479,13.815773 4.29570786,13.7017249 4.197,13.623 C4.10900749,13.545348 3.99633161,13.5014115 3.879,13.499 L2.774,13.499 C2.664,13.499 2.558,13.549 2.457,13.651 C2.36082293,13.7381946 2.31433731,13.8675296 2.333,13.996 C2.39058411,15.1679233 2.66169794,16.3194793 3.133,17.394 C3.61159916,18.4981386 4.29613108,19.50101 5.15,20.349 C6.34640828,21.5614573 7.8500872,22.4256406 9.5,22.849 C11.133218,23.2817827 12.8503774,23.2866042 14.486,22.863 C16.143,22.439 17.593,21.606 18.836,20.363 C20.08,19.12 20.917,17.666 21.35,16 C21.7828244,14.3614339 21.7828244,12.6385661 21.35,11 C20.9266406,9.3500872 20.0624573,7.84640828 18.85,6.65 C17.947417,5.74142984 16.8719083,5.02295058 15.687,4.537 C14.5189889,4.05295379 13.2641855,3.81336018 12,3.833 L12,0.905 Z" />
      <path d="M7.984,11.84 L9.592,11.36 L10.432,11.36 L10.432,17 L9.216,17 L9.216,12.608 L8.24,12.848 L7.984,11.84 Z M11.888,14.16 L12.048,11.4 L15.488,11.4 L15.488,12.48 L13.104,12.48 L13.04,13.448 C13.2798434,13.3827653 13.527448,13.3504691 13.776,13.352 C14.88,13.352 15.736,13.88 15.736,15.152 C15.736,16.344 14.896,17.096 13.608,17.096 C12.8241186,17.1129862 12.0671957,16.8096392 11.512,16.256 L12.256,15.368 C12.68,15.752 13.096,15.976 13.592,15.976 C14.168,15.976 14.528,15.696 14.528,15.192 C14.528,14.704 14.136,14.424 13.536,14.424 C13.184,14.424 12.864,14.52 12.6,14.632 L11.888,14.16 Z" />
    </Svg>
  )
}

export function IconForward() {
  return (
    <Svg width="20px" height="20px" color="#ffffff" viewBox="0 0 24 24">
      <title>skip-forward</title>
      <path d="M12,0.905 C12,0.721 12.097,0.587 12.29,0.505 C12.46248,0.418016836 12.6718483,0.457350203 12.801,0.601 L16.751,4.551 C16.8406736,4.63758441 16.8906094,4.757358 16.889,4.882 C16.889,5.012 16.843,5.122 16.751,5.214 L12.801,9.164 C12.6718483,9.3076498 12.46248,9.34698316 12.29,9.26 C12.097,9.177 12,9.044 12,8.86 L12,5.82 C11.000261,5.83581518 10.0121613,6.03723291 9.086,6.414 C8.14839257,6.79649364 7.29796453,7.36491974 6.586,8.085 C5.62728619,9.03087988 4.94448723,10.2201601 4.611,11.525 C4.27046032,12.8193087 4.27046032,14.1796913 4.611,15.474 C4.94465456,16.7784817 5.62744242,17.9673969 6.586,18.913 C7.53187988,19.8717138 8.72116009,20.5545128 10.026,20.888 C11.3203087,21.2285397 12.6806913,21.2285397 13.975,20.888 C15.2794817,20.5543454 16.4683969,19.8715576 17.414,18.913 C18.0826722,18.2498447 18.6212831,17.4673664 19.002,16.606 C19.3757542,15.7649599 19.5864832,14.8606234 19.623,13.941 C19.6393492,13.8156209 19.703918,13.7015493 19.803,13.623 C19.8909925,13.545348 20.0036684,13.5014115 20.121,13.499 L21.225,13.499 C21.336,13.499 21.442,13.549 21.543,13.651 C21.6391771,13.7381946 21.6856627,13.8675296 21.667,13.996 C21.6094159,15.1679233 21.3383021,16.3194793 20.867,17.394 C20.3884008,18.4981386 19.7038689,19.50101 18.85,20.349 C17.6535917,21.5614573 16.1499128,22.4256406 14.5,22.849 C12.866782,23.2817827 11.1496226,23.2866042 9.514,22.863 C7.857,22.439 6.407,21.606 5.164,20.363 C3.92,19.12 3.083,17.666 2.65,16 C2.2171756,14.3614339 2.2171756,12.6385661 2.65,11 C3.07335942,9.3500872 3.93754271,7.84640828 5.15,6.65 C6.05258295,5.74142984 7.12809167,5.02295058 8.313,4.537 C9.48101108,4.05295379 10.7358145,3.81336018 12,3.833 L12,0.905 Z" />
      <path d="M8.484,11.84 L10.092,11.36 L10.932,11.36 L10.932,17 L9.716,17 L9.716,12.608 L8.74,12.848 L8.484,11.84 Z M12.388,14.16 L12.548,11.4 L15.988,11.4 L15.988,12.48 L13.604,12.48 L13.54,13.448 C13.7798434,13.3827653 14.027448,13.3504691 14.276,13.352 C15.38,13.352 16.236,13.88 16.236,15.152 C16.236,16.344 15.396,17.096 14.108,17.096 C13.3241186,17.1129862 12.5671957,16.8096392 12.012,16.256 L12.756,15.368 C13.18,15.752 13.596,15.976 14.092,15.976 C14.668,15.976 15.028,15.696 15.028,15.192 C15.028,14.704 14.636,14.424 14.036,14.424 C13.684,14.424 13.364,14.52 13.1,14.632 L12.388,14.16 Z" />
    </Svg>
  )
}

export function IconPlay() {
  return (
    <Svg width="20px" height="20px" color="#ffffff" viewBox="0 0 448 512">
      <title>play</title>
      <path d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z" />
    </Svg>
  )
}

export function IconPause() {
  return (
    <Svg width="20px" height="20px" color="#ffffff" viewBox="0 0 448 512">
      <title>pause</title>
      <path d="M144 479H48c-26.5 0-48-21.5-48-48V79c0-26.5 21.5-48 48-48h96c26.5 0 48 21.5 48 48v352c0 26.5-21.5 48-48 48zm304-48V79c0-26.5-21.5-48-48-48h-96c-26.5 0-48 21.5-48 48v352c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48z" />
    </Svg>
  )
}
